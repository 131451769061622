// 
// user.js
// Use this to write your custom JS
//

const { Popover } = require("bootstrap");

/**
 * Sets up the contact form.
 */
 window.addEventListener('load', function() {
    const form = document.querySelector('#contact-form');
    const resultMessage = document.querySelector('#contact-result');

    if (form && resultMessage) {
        form.addEventListener('submit', function (e) {
            e.preventDefault();
        
            resultMessage.innerHTML = '';
        
            const name = document.querySelector('#contactName').value;
            const email = document.querySelector('#contactEmail').value;
            const subject = document.querySelector('#contactSubject').value;
            const message = document.querySelector('#contactMessage').value;
            const recaptchaToken = document.querySelector('#g-recaptcha-response').value;
            const contactFunction = form.dataset.target;

            if (!recaptchaToken || recaptchaToken.length === 0) {
                const popup = new Popover(document.querySelector('.g-recaptcha'), {
                    content: 'Please select this checkbox',
                    placement: 'top',
                });

                popup.show();

                setTimeout(function () {
                    popup.hide();
                }, 5000);

                return;
            }

            fetch(contactFunction, {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: name,
                    email: email,
                    subject: subject,
                    message: message,
                    recaptchaToken: recaptchaToken,
                }),
            })
                .then(function (res) {
                    if (!res.ok) {
                        throw Error(res.statusText);
                    }
                })
                .then(function (res) {
                    var message = document.createElement('div');
        
                    message.className = 'alert alert-success';
                    message.innerText = 'Your support request has been received. You will receive a response to your request within 1 to 2 business days. Our hours of availability are Monday through Friday from 9am to 5pm Eastern Time.';
        
                    resultMessage.appendChild(message);
        
                    form.reset();
                    grecaptcha.reset();
                })
                .catch(function (err) {
                    var message = document.createElement('div');
        
                    message.className = 'alert alert-danger';
                    message.innerHTML = 'Something went wrong while processing your form. Please try again, or send an email directly to <a href="mailto:support@telesage.com">support@telesage.com</a>';
        
                    resultMessage.appendChild(message);
        
                    console.error(err);
        
                    grecaptcha.reset();
                });
        });
    }
});
